@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  font-family: "Fira Sans", sans-serif;
  @apply text-slate-700;
}

button,
a.button,
button[type="submit"] {
  @apply pointer-events-auto rounded-md px-3 py-2 text-[0.8125rem] font-semibold leading-5;
  @apply bg-blue-600 text-white hover:bg-blue-500;
}

button[disabled], button:disabled {
  @apply opacity-10;
}

.center-breadcrumb {
  text-shadow: 0 0 2px rgb(0, 0, 0, 0.2);
  @apply text-black;
}
